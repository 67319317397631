import React from 'react';
import Link from "gatsby-link";
import {mediaBaseURL} from "../../../utils/constant";
import {Col} from "react-bootstrap";


function Servicessidemenu({data}) {
    return (
        <>
            {data?.map((value, i) => (
                <>
                    {value?.tabs?.map((item, i) => (
                        <Col xs={12} md={3} lg={3} xl={3} xxl={3} className="my-auto">
                            <div className="core_menu">
                                <div className="core_icon">
                                    <img
                                        src={`${mediaBaseURL}${item.icon}`} alt={item.title}/>
                                </div>
                                <div className="menu_items">
                                    <Link to={item.tab_link}>{item.title} <span>{item.tab_description}</span></Link>
                                </div>
                            </div>
                        </Col>
                    ))}
                </>
            ))}
        </>


    );
}

export default Servicessidemenu;
